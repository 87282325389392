import { FC, HTMLAttributes, useEffect, useRef, useState } from "react";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import { ArrowToggle, Checkbox } from "..";
import { useOutsideAlerter } from "../../hooks";
import { Spin } from "@shared/components";

export interface SelectOption {
  key: string;
  label: string;
}

export interface SelectProps extends HTMLAttributes<HTMLDivElement> {
  options: SelectOption[];
  value?: string[];
  handleChange?: (selected: string[]) => void;
  customLabel?: string;
  placeholder?: string;
  search?: boolean;
  handleSearch?: (query: string) => void;
  multiple?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
}

export const Select: FC<SelectProps> = ({
  options,
  value,
  handleChange,
  customLabel,
  placeholder = "",
  search = false,
  handleSearch,
  multiple = false,
  disabled = false,
  isLoading = false,
  className,
  ...componentProps
}: SelectProps) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>(value ?? []);
  const [query, setQuery] = useState<string>("");

  const getLabel = (key: string) =>
    options.find((option) => option.key === key)?.label ?? "";

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  useOutsideAlerter(wrapperRef, () => {
    setShowOptions(false);
    if (search) {
      if (query.length > 0) {
        setQuery(getLabel(selected[0]));
      } else {
        setSelected([]);
        if (handleChange) handleChange([]);
      }
    }
  });

  const handleSelect = (value: string) => {
    if (search) setQuery(getLabel(value));

    if (multiple) {
      if (selected.includes(value)) {
        const updatedState = selected.filter(
          (optionKey: string) => optionKey !== value
        );
        setSelected(updatedState);
        if (handleChange) handleChange(updatedState);
      } else {
        setSelected([...selected, value]);
        if (handleChange) handleChange([...selected, value]);
      }
    } else {
      setSelected([value]);
      setShowOptions(false);
      if (handleChange) handleChange([value]);
    }
  };

  const inputProps = {
    disabled,
    className: `selectBase w-full flex items-center justify-between rounded-xl 
    border px-4 py-2 text-sm font-medium z-20 disabled:cursor-not-allowed
    text-lightFontPrimary bg-lightCard hover:bg-lightPaper disabled:hover:bg-lightCard`,
  };

  const submenuAnimation: HTMLMotionProps<"div">["variants"] = {
    enter: {
      opacity: 1,
      rotateX: 0,
      transition: {
        duration: 0.3,
      },
      display: "block",
    },
    exit: {
      opacity: 0,
      rotateX: -15,
      transition: {
        duration: 0.2,
      },
      transitionEnd: {
        display: "none",
      },
    },
  };

  useEffect(() => {
    if (value) setSelected(value);
    if (search && value?.length === 0 && selected.length > 0) setQuery("");
  }, [value]);

  const filteredOptions = options.filter(
    (option: SelectOption) =>
      !search || option.label.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <div
      {...componentProps}
      className={`selectRoot w-64 relative ${
        showOptions ? "z-20" : "z-0"
      } ${className}`}
      ref={wrapperRef}
    >
      {search ? (
        <div className="relative">
          <input
            {...inputProps}
            placeholder={placeholder || "Search Option"}
            value={customLabel ?? query}
            onChange={(event) => {
              setQuery(event.target.value);
              if (handleSearch) handleSearch(event.target.value);
            }}
            onFocus={() => setShowOptions(true)}
            className={`truncate pr-8 ${inputProps.className}`}
          />
          <div className="absolute top-[16px] right-[18px]">
            <ArrowToggle trigger={showOptions} />
          </div>
        </div>
      ) : (
        <button {...inputProps} onClick={() => setShowOptions(!showOptions)}>
          <div className="selectLabel w-11/12 truncate text-left">
            {selected.length === 0 || options.length === 0 || isLoading ? (
              <span className="selectPlaceholder text-tagBackground opacity-40">
                {placeholder || "Select Option"}
              </span>
            ) : (
              customLabel ??
              selected.map(
                (optionKey: string, index: number) =>
                  `${
                    options.find(
                      (option: SelectOption) => option.key === optionKey
                    )?.label
                  }${index !== selected.length - 1 ? ", " : ""}`
              )
            )}
          </div>
          <ArrowToggle trigger={showOptions} />
        </button>
      )}

      <AnimatePresence>
        <motion.div
          animate={showOptions ? "enter" : "exit"}
          initial="exit"
          className={`selectOptionsBase mb-2 ${
            filteredOptions.length > 0 || options.length > 0
              ? "h-fit"
              : "h-[160px]"
          } overflow-auto absolute w-full rounded-md border`}
          variants={submenuAnimation}
        >
          <ul className="selectOptionsList z-30 h-full" role="none">
            {options.length > 0 && !isLoading ? (
              (handleSearch ? options : filteredOptions).map(
                ({ key, label }: SelectOption) => {
                  return (
                    <li
                      key={key}
                      id={key}
                      className="selectOption text-sm py-3 pl-3 z-30 text-lightFontPrimary
                    bg-lightCard hover:bg-lightPaper hover:cursor-pointer shadow-lg
                    first:rounded-t-md last:rounded-b-md"
                      onClick={(event) => handleSelect(event.currentTarget.id)}
                    >
                      <div className="selectOptionLabelContainer z-30 flex justify-between">
                        <span className="selectOptionLabel">{label}</span>

                        {multiple && (
                          <Checkbox
                            checked={selected.includes(key)}
                            handleClick={() => handleSelect(key)}
                          />
                        )}
                      </div>
                    </li>
                  );
                }
              )
            ) : (
              <div className="selectOptionLoader h-full w-full flex justify-center items-center bg-lightCard">
                {isLoading ? <Spin size={5} /> : "No options found"}
              </div>
            )}
          </ul>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
