import { FC, HTMLAttributes } from "react";

export interface PingProps extends HTMLAttributes<HTMLDivElement> {}

export const Ping: FC<PingProps> = ({ className, ...componentProps }) => (
  <div
    {...componentProps}
    className={`pingRoot ml-1 w-4 flex items-center relative ${className}`}
  >
    <span className="pingBase flex h-2 w-2">
      <span className="pingRipple animate-ping absolute inline-flex h-2 w-2 rounded-full bg-[#3dc282] opacity-75"></span>
      <span className="pingBody relative inline-flex rounded-full h-2 w-2 bg-[#3BCB86]"></span>
    </span>
  </div>
);
