import { useCallback, useEffect, useState } from "react";

/**
 * Hook that optimizes user interaction with portal components.
 *
 * @param isOpen Open state of portal.
 * @param handleClose Close portal handler.
 *
 * @returns Boolean value of rendering state.
 */

export const usePortalHandlers = (isOpen: boolean, handleClose: () => void) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!isOpen || event.key !== "Escape") return;

      handleClose();
    },
    [handleClose, isOpen]
  );

  useEffect(() => {
    if (!isOpen) return;

    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown, isOpen]);

  const [isBrowser, setIsBrowser] = useState(false);
  useEffect(() => {
    setIsBrowser(true);
  }, []);

  return isBrowser;
};
