import { useDeId } from "@dustlabs/profiles/core";
import { ThemeContext } from "@shared/constants";
import { useRouter } from "next/router";
import React, { FC, ReactNode, useEffect, useState } from "react";

interface Props {
  children?: ReactNode;
}

const ThemeContextProvider: FC<Props> = ({ children }: Props) => {
  const router = useRouter();
  const { isSignedIn } = useDeId();
  const [darkMode, setDarkMode] = useState<boolean>(false);

  const toggleDarkMode = (newMode: boolean) => {
    setDarkMode(newMode);
  };

  useEffect(() => {
    if (router.pathname !== "holdings" || !isSignedIn) {
      setDarkMode(false);
    }
  }, [isSignedIn, router.pathname]);

  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      <div className={darkMode ? "dark" : ""}>{children}</div>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
