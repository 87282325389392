import axios from "axios";
import { GLOBAL_CONFIG } from "@shared/constants";
import { useQuery } from "react-query";
import { CollectionStats } from "@explorer/types";

export const getCollectionStats = async (): Promise<CollectionStats> => {
  return await axios
    .get(`${GLOBAL_CONFIG.API_URL}/${GLOBAL_CONFIG.ID}/marketStats`)
    .then((res) => {
      return res?.data?.stats;
    })
    .catch((err) => {
      console.error("getCollectionStats error ", err.message);
      return undefined;
    });
};

export const useCollectionStats = () => {
  return useQuery({
    queryKey: "collectionStats",
    queryFn: getCollectionStats,
  });
};
