import { GLOBAL_CONFIG } from "@shared/constants";
import { AppConfig } from "@shared/types";

export const useAppConfig = (appKey: string) => {
  const moreMenuConfig = GLOBAL_CONFIG.APPS.find(
    (appItem) => appItem.key === "more"
  ) as AppConfig;

  const moreMenuApps =
    moreMenuConfig.moreMenuContent?.flatMap((op) => op.column) ?? [];

  const appConfig = [...GLOBAL_CONFIG.APPS, ...moreMenuApps].find(
    (appItem) => appItem.key === appKey
  ) as AppConfig;

  return appConfig;
};
