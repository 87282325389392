import { useWindowSize } from "@dustlabs/web/hooks";

/**
 * Hook that allows programmatically accessing default Tailwind specific breakpoints
 *
 * @param breakpoint Tailwind breakpoint to check against
 *
 * @returns Whether the current window width is within the breakpoint
 */
export const useBreakpoint = (
  breakpoint: "xs" | "sm" | "md" | "lg" | "xl" | "2xl"
) => {
  const [winWidth] = useWindowSize();

  switch (breakpoint) {
    case "xs":
      return winWidth >= 0;
    case "sm":
      return winWidth >= 640;
    case "md":
      return winWidth >= 768;
    case "lg":
      return winWidth >= 1024;
    case "xl":
      return winWidth >= 1280;
    case "2xl":
      return winWidth >= 1536;
  }
};
