import { useCallback, useEffect, useRef, useState } from "react";

/**
 * Hook that detects scroll position within a container.
 *
 * @returns Boolean values related to whether there are items on top or bottom,
 * container ref and onscroll handler.
 */

export const useScrollDetector = () => {
  const [itemsAtTop, setItemsAtTop] = useState<boolean>(false);
  const [itemsAtBottom, setItemsAtBottom] = useState<boolean>(false);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = useCallback(() => {
    if (scrollContainerRef.current) {
      const atStart = scrollContainerRef.current?.scrollTop < 10;
      const reachedEnd =
        scrollContainerRef.current?.scrollHeight -
          scrollContainerRef.current?.scrollTop -
          10 <
        scrollContainerRef?.current?.clientHeight;

      const needScroll =
        scrollContainerRef?.current?.clientHeight <
        scrollContainerRef?.current?.scrollHeight;

      setItemsAtTop(Boolean(needScroll && !atStart));
      setItemsAtBottom(Boolean(needScroll && !reachedEnd));
    }
  }, [scrollContainerRef.current]);

  useEffect(() => {
    handleScroll();
  }, [handleScroll]);

  return { itemsAtTop, itemsAtBottom, scrollContainerRef, handleScroll };
};
