import { useQuery } from "react-query";
import axios from 'axios';
import { GLOBAL_CONFIG } from "@shared/constants";

export const useJupiterData = (
  enabled: boolean,
  tokenType: string,
  outstandingBalance: number,
) => {
  
  return useQuery(
    ["jupiterQuote", tokenType],
    async () => {
      const outputMint = GLOBAL_CONFIG.DUST_TOKEN_ADDRESS;
      const inputMint =
        tokenType === "USDC"
          ? "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
          : "So11111111111111111111111111111111111111112";
      const { data } = await axios.get(`https://quote-api.jup.ag/v3/quote`, {
        params: {
          inputMint,
          outputMint,
          amount: (outstandingBalance ?? 0) * 10 ** 9,
          swapMode: "ExactOut",
          slippage: 10,
          feeBps: 0,
          onlyDirectRoutes: true,
        },
      });
      return data;
    },
    {
      refetchInterval: 6000,
      enabled,
    }
  );
};